* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: lightblue;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-size: 40px;
}

.flex {
  display: flex;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cursor {
  color: white;
}

.texture-selector {
  transform: scale(5);
}

.texture-selector img.active {
  border: 2px solid red;
}

.menu {
  top: 0px;
  left: 10px;
}
